import React, { useRef, useEffect, useState } from 'react';
import './App.css';

export default function ContactBox({ name, mail, info = null }) {
    return (
        <div className="group w-[300px] m-3">
            <div className="relative p-6 bg-gray-800/50 backdrop-blur-xl rounded-2xl border border-gray-700/50 transition-all duration-300 hover:scale-[1.02] hover:shadow-xl hover:shadow-blue-500/20 hover:border-blue-500/50 overflow-hidden">
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="relative z-10 space-y-3">
                    <h3 className="text-3xl font-bold">
                        <a
                            href={`mailto:${mail}`}
                            className="bg-gradient-to-r from-blue-400 to-blue-200 hover:from-blue-300 hover:to-blue-100 bg-clip-text text-transparent transition-all duration-300"
                        >
                            {name}
                        </a>
                    </h3>

                    {info && info.githubUsername && (
                        <div className="flex items-center space-x-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="text-gray-400 group-hover:text-gray-300 transition-colors duration-300"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                            </svg>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://github.com/${info.githubUsername}`}
                                className="text-xl font-light text-gray-300 hover:text-blue-300 transition-colors duration-300"
                            >
                                {info.githubUsername}
                            </a>
                        </div>
                    )}

                    {/* Subtle shine effect */}
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-[200%] group-hover:translate-x-[200%] transition-transform duration-1000" />
                </div>
            </div>
        </div>
    );
}