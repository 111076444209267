import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import ContactBox from "./ContactBox";
import { Howl } from 'howler';
import { Info } from 'lucide-react';

import monologueSound from './monologue.ogg';

import helvetikerFont from '../node_modules/three/examples/fonts/helvetiker_regular.typeface.json';

function LemhLogo() {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const meshesRef = useRef([]);
  const backgroundMeshRef = useRef(null);

  const initScene = () => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 10;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    addLights(scene);
  };

  const addLights = (scene) => {
    const ambientLight = new THREE.AmbientLight(0x404040, 1.5);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0x8b5cf6, 1, 100);
    pointLight1.position.set(10, 10, 10);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0x10b981, 1, 100);
    pointLight2.position.set(-10, -10, -10);
    scene.add(pointLight2);
  };

  const createBackground = (scene) => {
    const geometry = new THREE.PlaneGeometry(20, 20);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 }
      },
      vertexShader: `
        varying vec2 vUv;
        uniform float time;
        void main() {
          vUv = uv;
          vec3 pos = position;
          pos.z += sin(pos.x * 2.0 + time) * 0.1;
          pos.z += cos(pos.y * 2.0 + time) * 0.1;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        varying vec2 vUv;
        void main() {
          vec3 color1 = vec3(0.05, 0.01, 0.1);
          vec3 color2 = vec3(0.1, 0.05, 0.2);
          float noise = fract(sin(dot(vUv, vec2(12.9898, 78.233))) * 43758.5453);
          noise += sin(time * 0.5) * 0.1;
          vec3 mixedColor = mix(color1, color2, noise);
          gl_FragColor = vec4(mixedColor, 0.8);
        }
      `,
      transparent: true
    });

    const backgroundMesh = new THREE.Mesh(geometry, material);
    backgroundMesh.position.z = -10;
    scene.add(backgroundMesh);
    backgroundMeshRef.current = backgroundMesh;
  };

  const createLetters = () => {
    const fontLoader = new FontLoader();
    const font = fontLoader.parse(helvetikerFont);

    const letters = ['L', 'E', 'M', 'H'];
    const colors = [0xf87171, 0x8b5cf6, 0xfb923c, 0x10b981];
    const glowColors = [0xff4444, 0x6a4cff, 0xff7f32, 0x2ecc71];

    letters.forEach((letter, index) => {
      const geometry = new TextGeometry(letter, {
        font: font,
        size: 2,
        height: 0.5,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.1,
        bevelSize: 0.1,
        bevelOffset: 0,
        bevelSegments: 5
      });

      geometry.computeBoundingBox();
      geometry.center();

      const material = new THREE.MeshStandardMaterial({
        color: colors[index],
        metalness: 0.7,
        roughness: 0.3,
        emissive: glowColors[index],
        emissiveIntensity: 0.5
      });

      const letterMesh = new THREE.Mesh(geometry, material);
      letterMesh.position.x = (index - 1.5) * 3;
      letterMesh.position.z = 0;

      // Ajouter un effet de glow
      const glowMaterial = new THREE.MeshBasicMaterial({
        color: glowColors[index],
        transparent: true,
        opacity: 0.3
      });
      const glowGeometry = new THREE.BufferGeometry().copy(geometry);
      const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
      glowMesh.scale.multiplyScalar(1.1);

      sceneRef.current.add(letterMesh);
      sceneRef.current.add(glowMesh);

      meshesRef.current.push({ letter: letterMesh, glow: glowMesh });
    });
  };

  const animate = () => {
    const timeScale = performance.now() * 0.001;
    requestAnimationFrame(animate);

    // Animation des lettres
    meshesRef.current.forEach(({ letter, glow }, index) => {
      letter.rotation.y = Math.sin(timeScale + index) * 0.5;
      letter.rotation.x = Math.cos(timeScale + index) * 0.3;
      letter.position.z = Math.sin(timeScale + index * 0.5) * 0.1;

      // Synchroniser le glow avec la lettre
      glow.rotation.copy(letter.rotation);
      glow.position.copy(letter.position);
    });

    // Animation du fond
    if (backgroundMeshRef.current) {
      backgroundMeshRef.current.material.uniforms.time.value = timeScale;
    }

    // Effet de respiration
    const breatheScale = Math.sin(timeScale) * 0.05 + 1;
    meshesRef.current.forEach(({ letter, glow }) => {
      letter.scale.set(breatheScale, breatheScale, breatheScale);
      glow.scale.set(
          breatheScale * 1.1,
          breatheScale * 1.1,
          breatheScale * 1.1
      );
    });

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const handleResize = () => {
    const { innerWidth, innerHeight } = window;
    cameraRef.current.aspect = innerWidth / innerHeight;
    cameraRef.current.updateProjectionMatrix();
    rendererRef.current.setSize(innerWidth, innerHeight);
  };

  useEffect(() => {
    initScene();
    createLetters();

    animate();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeChild(rendererRef.current.domElement);
    };
  }, []);

  return <div ref={containerRef} className="w-full h-full absolute top-0 left-0" />;
}


function Accueil({ onNavClick }) {
  return <div className="relative z-20 text-center">
    <h1 className="text-8xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
      <HoverName/>
    </h1>
    <p className="text-2xl max-w-3xl mx-auto mb-16 text-gray-300 mt-6 leading-relaxed">
      Bienvenue chez LEMH, pas de monologue ici.
    </p>
    <div className="space-x-6">
      <button className="px-8 py-4 text-xl bg-blue-500 hover:bg-blue-600 rounded-full transition-colors">
       <a
           onClick={(e) => {
             e.preventDefault();
             onNavClick("À propos");
           }}>
         Découvrir
       </a>
      </button>
      <button
          className="px-8 py-4 text-xl border border-white hover:bg-white hover:text-black rounded-full transition-colors">
        <a
            onClick={(e) => {
              e.preventDefault();
              onNavClick("Projets");
            }}>
          Nos projets
        </a>
      </button>
    </div>
  </div>
}

function ProjectCard({ title, image, link, date, github, author, description }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
      <div className="group w-full lg:w-[calc(50%-1rem)]">
        <div
            className="relative bg-gray-800/50 backdrop-blur-xl p-4 sm:p-6 rounded-2xl shadow-xl border border-gray-700/50 flex flex-col sm:flex-row transform transition-all duration-300 hover:shadow-2xl hover:shadow-blue-500/20 hover:border-blue-500/50">
          <div
              className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>

          <div
              className="relative w-full sm:w-48 h-40 sm:h-48 min-w-[auto] sm:min-w-[12rem] mb-4 sm:mb-0 sm:mr-6 overflow-hidden rounded-xl">
            <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/20 to-purple-500/20 z-10"/>
            <img
                src={image}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                alt={title}
            />
          </div>

          <div className="flex flex-col justify-center relative z-10 flex-1">
            <div className="space-y-1 mb-4">
              <div className="flex items-start justify-between">
                <h2 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent line-clamp-2">
                  {title}
                </h2>
                {description && (
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className={`relative p-2 rounded-xl transition-colors duration-300 group/info ml-2 flex-shrink-0
                    ${isExpanded ? 'bg-blue-500/20 text-blue-300' : 'hover:bg-gray-700/50 text-gray-400 hover:text-gray-300'}`}
                    >
                      <Info size={18} className="transition-transform duration-300"/>
                      <div
                          className="absolute inset-0 rounded-xl bg-blue-500/10 opacity-0 group-hover/info:opacity-100 transition-opacity duration-300"/>
                    </button>
                )}
              </div>
              <h3 className="text-lg sm:text-xl text-gray-200">{author}</h3>
              <h3 className="text-base sm:text-lg text-gray-400 font-light">{date}</h3>
            </div>

            <div className={`overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96 mb-4' : 'max-h-0'}`}>
              <p className="text-sm sm:text-base text-gray-300 font-light leading-relaxed line-clamp-5">
                {description}
              </p>
            </div>

            <div className="flex flex-wrap gap-2 sm:gap-3">
              {link && (
                  <a
                      target={link.startsWith('http') ? '_blank' : '_self'}
                      href={link}
                      className="relative px-4 sm:px-6 py-2 sm:py-2.5 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl text-sm sm:text-base font-medium shadow-lg shadow-blue-500/30 hover:shadow-blue-500/50 hover:to-blue-400 transition-all duration-300 w-fit group/button overflow-hidden"
                  >
                    <span className="relative z-10">Tester</span>
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-blue-400 to-blue-300 translate-y-full group-hover/button:translate-y-0 transition-transform duration-300"/>
                  </a>
              )}

              {github && (
                  <a
                      href={github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative px-4 sm:px-6 py-2 sm:py-2.5 bg-gradient-to-r from-purple-600 to-purple-500 text-white rounded-xl text-sm sm:text-base font-medium shadow-lg shadow-purple-500/30 hover:shadow-purple-500/50 hover:to-purple-400 transition-all duration-300 w-fit group/github overflow-hidden flex items-center gap-2"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="relative z-10 sm:w-5 sm:h-5"
                        viewBox="0 0 16 16"
                    >
                      <path
                          d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                    </svg>
                    <span className="relative z-10">GitHub</span>
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-purple-400 to-purple-300 translate-y-full group-hover/github:translate-y-0 transition-transform duration-300"/>
                  </a>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

function Projets() {
  return (
      <div className="space-y-6 sm:space-y-8 px-4 sm:px-6 mx-auto max-w-7xl">
        <div className="space-y-2 max-w-4xl mx-auto">
          <h2 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-400 mb-4 sm:mb-8 mt-16 sm:mt-16 z-50 relative">
            Projets
          </h2>
          <h3 className="text-2xl sm:text-3xl md:text-4xl text-gray-400/90 font-light mb-4 sm:mb-8 z-50 relative">
            Certains projets sont fait par une seule personne, d'autres par plusieurs.
          </h3>
        </div>
        <div className="flex flex-wrap gap-4 sm:gap-8 max-w-6xl mx-auto">
          <ProjectCard
              title="Worst Code Ever"
              image={require('./media/wce.png')}
              date="NDI 2024 (Décembre)"
              github={"https://github.com/Clement-Labbe-Etu/Worst-Code-ever"}
              author={"🏆 CHLEM 🏆"}
              description={"Le pire code possible en C, à l'occasion de la NDI 2024 pour le défi WorstCodeEver de CAPCOD ! Ce code a permis la victoire de l'équipe au défi face à 142 autres équipes !"}
          />
          <ProjectCard
              title="Merveille"
              image={require('./media/merveille.png')}
              link="https://nesx64.github.io/merveille"
              date="Décembre 2024"
              author={"Loan"}
              description={"Projet de développement d'un site web à l'occasion de la nuit de l'info 2024, sur le thème du parallèle entre le corps humain et les océans !"}
          />
          <ProjectCard
              title="Minecraftdle"
              image={require('./media/mcdle.png')}
              link="https://www.minecraftdle.net/"
              date="Mars 2024 - Aujourd'hui"
              author={"Matéo"}
              description={"Probablement le meilleur DLE qui existe, qui plus est sur Minecraft !"}
          />
          <ProjectCard
              title="TodoREST"
              image={"https://github.com/nesx64/todo-api/raw/master/illust.png"}
              date="Décembre 2024"
              github={"https://github.com/nesx64/todo-api"}
              author={"Loan"}
              description={"API REST basique en cours de développement en Java Spring Boot."}
          />
          <ProjectCard
              title="Japanese Conjugator"
              image={require('./media/japanese-conjugator.png')}
              link={"https://hugoretail.github.io/Japanese-Conjugator/"}
              date="Été 2024"
              github={"https://github.com/hugoretail/Japanese-Conjugator"}
              author={"Hugo"}
              description={"Un site web pour s'entrainer à la conjugaison japonaise, un excellent outil !"}
          />
          <ProjectCard
              title="libft"
              image={require('./media/c.webp')}
              date="mi-2024"
              github={"https://github.com/evanrmtl/libft"}
              author={"Evan"}
              description={"Une implémentation des librairies standards du C. Pas facile !"}
          />
        </div>
      </div>
  );
}


function APropos() {
  return <div>
    <h2 className={"text-4xl sm:text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-400 mb-4 sm:mb-8 mt-16 sm:mt-16 z-50 relative"} style={{fontSize: '64px', color: 'white', zIndex: "999", position: "relative"}}>À propos</h2>
    <h2 style={{fontSize: '48px', color: 'white', zIndex: "999", position: "relative"}}>À venir...</h2>
  </div>;
}

function Contact() {
  return <>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <h2 style={{fontSize: '64px', color: 'white', zIndex: "999", position: "relative"}} className={"text-4xl sm:text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-400 mb-4 sm:mb-8 mt-16 sm:mt-16 z-50 relative"}>Contact</h2>
      <ContactBox mail="contact@lemh.fr" name="Contact du site"/>
      <ContactBox mail="loan.collomb@u-bordeaux.fr" name="Loan" info={{githubUsername:"nesx64"}}/>
      <ContactBox mail="evan.rimonteil@u-bordeaux.fr" name="Evan" info={{githubUsername:"evanrmtl"}}/>
      <ContactBox mail="mateo.guidi@etu.u-bordeaux.fr" name="Matéo" info={{githubUsername:"MateoGuidi"}}/>
      <ContactBox mail="hugo.retail@u-bordeaux.fr" name="Hugo" info={{githubUsername:"hugoretail"}}/>
    </div>
  </>;
}
export default function LemhWebsite() {

  useEffect(() => {
    const konamiKeys = {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      65: 'a',
      66: 'b'
    };

    const konamiSequence = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    let pos = 0;

    const enableKonami = () => {
      const audio = new Howl({
        src: [monologueSound],
        autoplay: true,
        volume: 1.0,
        onloaderror: (id, error) => console.error('Failed to load sound:', error),
        onplayerror: (id, error) => console.error('Failed to play sound:', error)
      });
    };

    const handleKeyDown = (event) => {
      let key = konamiKeys[event.keyCode];
      let requiredKey = konamiSequence[pos];
      if (key === requiredKey) {
        pos++;
        if (pos === konamiSequence.length) {
          enableKonami();
          pos = 0; // Optionnel, pour réinitialiser la séquence après avoir joué la musique
        }
      } else {
        pos = 0;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleNavigationClick = (page) => {
    setActivePage(page);
  };

  const [activePage, setActivePage] = useState('Accueil');

  return (
      <div
          className="min-h-screen bg-black text-white overflow-hidden relative flex flex-col items-center justify-center">
        <Navigation onNavClick={handleNavigationClick}/>

        <div className="absolute inset-0 opacity-50">
          <LemhLogo/>
        </div>
        <div className="content-container">
        {activePage === 'Accueil' && <Accueil onNavClick={handleNavigationClick} />}
        {activePage === 'Projets' && <Projets />}
        {activePage === 'À propos' && <APropos />}
        {activePage === 'Contact' && <Contact />}
      </div>
    </div>
  );
}


// Composants Navigation et HoverName restent identiques
function Navigation({ onNavClick }) {
  const navItems = [
    { name: 'Accueil', link: 'Accueil' },
    { name: 'Projets', link: 'Projets' },
    { name: 'À propos', link: 'À propos' },
    { name: 'Contact', link: 'Contact' },
  ];

  return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-opacity-50 bg-gray-900 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold text-white flex items-center">
            <img src={require('./media/logo.ico')} alt="LEMH" className="h-8"/>
            <span className={"ml-2"}>LEMH</span>
          </div>
          <ul className="flex space-x-6">
            {navItems.map((item) => (
                <li key={item.name}>
                  <a
                      href=""
                      className="text-white hover:text-blue-400 transition-colors"
                      onClick={(e) => {
                          e.preventDefault();
                          onNavClick(item.link);
                      }}
                  >
                    {item.name}
                  </a>
                </li>
            ))}
          </ul>
        </div>
      </nav>
  );
}



function HoverName() {
  const [hoveredLetter, setHoveredLetter] = useState(null);

  const letterDetails = {
    L: { full: "Loan", color: "text-red-400" },
    E: { full: "Evan", color: "text-violet-400" },
    M: { full: "Matéo", color: "text-orange-400" },
    H: { full: "Hugo", color: "text-emerald-500" },
  };

  return (
    <div className="flex space-x-12 items-center justify-center">
      {Object.entries(letterDetails).map(([letter, details]) => (
        <div
          key={letter}
          className="flex items-center space-x-4"
          onMouseEnter={() => setHoveredLetter(letter)}
          onMouseLeave={() => setHoveredLetter(null)}
        >
          {/* Lettre */}
          <span
            className={`text-8xl font-bold transition-transform duration-300 ${
              hoveredLetter === letter ? "scale-125" : "scale-100"
            } ${details.color}`}
          >
            {letter}
          </span>

          {/* Texte étendu avec taille augmentée */}
          <span
            className={`text-4xl font-semibold overflow-hidden transition-all duration-300 ${
              hoveredLetter === letter ? "opacity-100 max-w-xs" : "opacity-0 max-w-0"
            } ${details.color}`}
            style={{ whiteSpace: "nowrap" }}
          >
            {details.full}
          </span>
        </div>
      ))}
    </div>
  );
}
